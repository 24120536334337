<script>
import { SuawMainContent, SuawSection, SuawCarouselRows, SuawCard } from "@suaw/suaw-component-library";

export default {
  name: "ResourcesIndex",
  components: {
    SuawMainContent,
    SuawSection,
    SuawCarouselRows,
    SuawCard
  },
  methods: {
    nav(val) {
      this.$router.push({ name: val });
    }
  }
};
</script>

<template>
  <SuawMainContent
    :show-sub-header="true"
    sub-header-title="Resources"
    sub-header-desc="Git somme subheader description with help of your product owner and copy writer today!"
  >
    <SuawSection>
      <SuawCarouselRows carousel-heading="General Resources">
        <template #cards>
          <SuawCard id="card_article" card-type="CardArticle" heading="About" @click="nav('Resources-About')" />
          <SuawCard id="card_article" card-type="CardArticle" heading="FAQ" @click="nav('Resources-Faq')" />
        </template>
      </SuawCarouselRows>
    </SuawSection>
    <SuawSection>
      <SuawCarouselRows carousel-heading="Member Resources">
        <template #cards>
          <SuawCard id="card_article" card-type="CardArticle" heading="Code of Conduct" @click="nav('Resources-CodeOfConduct')" />
        </template>
      </SuawCarouselRows>
    </SuawSection>
    <SuawSection>
      <SuawCarouselRows carousel-heading="Organizer Resources">
        <template #cards>
          <SuawCard id="card_article" card-type="CardArticle" heading="Become an Organizer" @click="nav('Resources-BecomeAnOrganizer')" />
          <SuawCard id="card_article" card-type="CardArticle" heading="Downloads" @click="nav('Resources-Downloads')" />
          <SuawCard id="card_article" card-type="CardArticle" heading="Organizer FAQ" @click="nav('Resources-OrganizerFaq')" />
          <SuawCard id="card_article" card-type="CardArticle" heading="Organizer Handbook" @click="nav('Resources-OrganizerHandbook')" />
        </template>
      </SuawCarouselRows>
    </SuawSection>
  </SuawMainContent>
</template>
